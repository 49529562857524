import React from 'react';

import Layout from '../layouts/layout';
import SEO from '../components/common/SEO';
import Content from '../components/common/Content';
import notFoundSvg from '../images/icons/not-found.svg';
import { FormattedMessage } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import { spacing } from '../styles';

const StyledImg = styled.img`
  width: 100%;
  padding: ${spacing['4']};
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Content>
      <StyledImg src={notFoundSvg} />
      <h1 style={{ textAlign: 'center' }}>
        <FormattedMessage id="common.pageNotFound" />
      </h1>
    </Content>
  </Layout>
);

export default NotFoundPage;
